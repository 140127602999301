/* @font-face {
  font-family: 'Poppins-light';
  src: url('./fonts/Poppins-Light.ttf') format('truetype');
} */

@font-face {
  font-family: 'nunito';
  src: url('./fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
}

html input, html textarea {
  font-family: nunito !important;
}


@font-face {
  font-family: 'Nunito-extralight';
  src: url('./fonts/NunitoSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-light';
  src: url('./fonts/NunitoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-regular';
  src: url('./fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-semibold';
  src: url('./fonts/NunitoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-medium';
  src: url('./fonts/NunitoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-bold';
  src: url('./fonts/NunitoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito-extrabold';
  src: url('./fonts/NunitoSans-ExtraBold.ttf') format('truetype');
}


/* 
@font-face {
  font-family: 'Poppins-bold';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-extraBold';
  src: url('./fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-semibold';
  src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-medium';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-regular';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
} */

body {
  font-family: Nunito-light;
  margin: 0;
  padding: 0;
}

html .pac-item.pac-item-selected .pac-logo {
  display: none !important;
}
html::-webkit-scrollbar {
  width: 10px; 
}

html::-webkit-scrollbar-track {
  background: #f1f1f1;
}

html::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 5px; 
}


